import Vue from 'vue'
import App from './App.vue'
import fetch from "node-fetch";
import router from "./router"
import VueResource from 'vue-resource'
import VueWorker from 'vue-worker'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { library } from '@fortawesome/fontawesome-svg-core'
//import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle, faCheckCircle, faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
// import { fafontbrands } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMoment from 'vue-moment'
import { VLazyImagePlugin } from 'v-lazy-image'
const moment = require('moment')
require('moment/locale/fr');
var VueScrollTo = require('vue-scrollto');

Vue.use(VueResource)
Vue.use(fetch)
Vue.use(VueWorker)
Vue.use(VueMoment, { moment })
Vue.use(VueReCaptcha, { siteKey: '6LcugE0fAAAAAJ9imYciCKTxGLCCZSUEV0cjjw-J' })
Vue.use(VLazyImagePlugin)

library.add(faCircle, faCheckCircle, faSquare, faCheckSquare)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')


Vue.filter('money', function(value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default { 
  created(){
    this.$moment.locale('fr');
  }
}

</script>

<style src="./style.css"></style>
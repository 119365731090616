import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'hash',
    routes: [{
            path: '/',
            component: require('@/pages/page_account_login').default
        },
        {
            path: '/account/login',
            component: require('@/pages/page_account_login').default
        }, {
            path: '/account/create',
            component: require('@/pages/page_account_create').default
        }, {
            path: '/account',
            component: require('@/pages/page_account').default
        }, {
            path: '/board',
            component: require('@/pages/page_board').default
        }, {
            path: '/reservation',
            component: require('@/pages/page_reservation').default
        }, {
            path: '/invoices',
            component: require('@/pages/page_invoices').default
        }, {
            path: '/rentals',
            component: require('@/pages/page_rentals').default
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
})
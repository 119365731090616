<template>
  <div>
    <div v-if="isPayfipValidation && forceHome == false">
      <div class="line" style="text-align: center; margin-top: 120px">
        <i
          v-if="smileySmiling == true"
          style="color: var(--BUTTON)"
          class="far fa-smile-beam fa-4x"
        ></i>
        <i
          v-if="smileySmiling == false"
          style="color: var(--BUTTON)"
          class="far fa-frown fa-4x"
        ></i>
        <h1>{{ msgtitle }}</h1>
        <h3>{{ msgsubtitle }}</h3>
        <button
          v-if="smileySmiling !== undefined"
          class="fbutton"
          @click="backToRental()"
        >
          Retour
        </button>
      </div>
    </div>
    <div v-else class="application">
      <menu-top :account="account" />
      <div class="line" style="text-align: center">
        <h1>Vos contrats de location</h1>
        <h3>Historique de vos contrats de locations</h3>
      </div>
      <div class="line-m" style="margin-top: 40px">
        <table class="ftable">
          <tr>
            <th>Numéro de contrat</th>
            <th>Date de départ</th>
            <th>Date de retour</th>
            <th>Numéro de vélo</th>
            <th>Numéro de facture</th>
            <th>Numéro de réservation</th>
            <th>Prix</th>
          </tr>
          <tr v-for="rental in list" :key="rental.id">
            <td>{{ rental.rental_number }}</td>
            <td>
              {{ $moment(rental.rental_date_depart).format("DD/MM/YYYY") }}
            </td>
            <td>
              {{ $moment(rental.rental_date_retour).format("DD/MM/YYYY") }}
            </td>
            <td>{{ rental.matricule }}</td>
            <td>{{ rental.rental_facture_number }}</td>
            <td>{{ rental.rental_reservation_number }}</td>
            <td>{{ rental.rental_price }}€</td>
          </tr>
        </table>
      </div>
    </div>
    <loadingform v-if="loading"></loadingform>
  </div>
</template>

<script>
import engine from "@/core/data/DataAPI";
import MenuTop from "@/components/MenuTop";
import loadingform from "@/core/controls/LoadingForm";

export default {
  components: {
    MenuTop,
    loadingform,
  },
  data() {
    return {
      loading: false,
      account: {},
      vdate: "",
      list: [],
      smileySmiling: undefined,
      forceHome: false,
    };
  },
  mounted() {
    let that = this;
    this.$moment.locale("fr");
    this.loading = true;
    engine.Account_Info(function (pkg) {
      if (pkg.code != 0) {
        that.loading = false;
        that.$router.push("/");
      } else {
        that.account = pkg.data[0];
        engine.Rentals_List(function (pkgrental) {
          if (pkgrental.code == 0) {
            that.list = pkgrental.data;
            if (that.isPayfipValidation) {
              let params = that.$route.query.state;
              if (params == "effectue") {
                that.smileySmiling = true;
                that.msgtitle = "Votre paiement a bien été effectué.";
                that.editRenouvelementContract();
                // that.msgsubtitle =
                //   "Vous allez être contacté par notre équipe pour convenir d'un rendez-vous.";
              } else {
                that.smileySmiling = false;
                that.msgtitle = "Votre paiement" + " a rencontré une erreur.";
                // that.msgsubtitle =
                //   "Veuillez contacter la Synchro Vélostation par e-mail au contact@velostation-chambery.fr";
                // that.loading = false;
              }
            }
          }
        });
      }
      that.loading = false;
    });
  },
  computed: {
    isPayfipValidation() {
      var field = "state";
      var url = window.location.href;
      if (url.indexOf("?" + field + "=") != -1) return true;
      else if (url.indexOf("&" + field + "=") != -1) return true;
      return false;
    },
  },
  methods: {
    editRenouvelementContract() {
      let num = this.$route.query.resa_number;
      // let contract = this.list.find(
      //   (data) => data.rental_reservation_number == num
      // );
      this.list.find((data) => data.rental_reservation_number == num);
    },
    backToRental() {
      this.$router.push("/board");
      this.forceHome = true;
    },
    renewContrat(contrat) {
      var datenow = this.$moment().year();
      var amount = contrat.rental_price * 100;
      var reference =
        "RSWR" + this.$moment().format("DDMM") + engine.Guid().slice(-2);
      var mail = this.account.email;
      var data = {
        nom: this.account.lastname,
        mel: mail,
        montant: amount,
        objet: "paiement de votre reservation Synchro Vélostation",
        refdet: reference,
        exer: datenow,
        facture: contrat.rental_facture_number,
        contract: contrat.rental_number,
        numero: contrat.rental_reservation_number,
      };
      engine.Payfip_init_renew(data, function (pkg) {
        if (pkg.code == 0) {
          window.open(pkg.data, "_self");
        }
      });
      // let tmain = this
      // engine.Rentals_byId(id, function (data) {
      // let pkgp = {
      //   material: tmain.material_selected,
      //   agencies: tmain.agency_selected,
      //   prices: tmain.lbl_amount,
      //   datedebut: tmain.date_retrait,
      //   datefin: tmain.date_fin,
      //   options: {
      //     is_main_bank: tmain.account.is_main_bank,
      //     banq_account: tmain.banq_account,
      //   },
      // };
      // });
    },
    donwloadRental: function (rental) {
      engine.File_Rental_Download(rental.rental_number, function (pkgfile) {
        var extname = ".pdf";
        var fileURL = window.URL.createObjectURL(new Blob([pkgfile.data]));
        var download_link = document.createElement("a");
        download_link.href = fileURL;
        download_link.download = rental.rental_number + extname;
        download_link.target = "_blank";
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
      });
    },
    navigation: function (app) {
      var tmain = this;
      tmain.$router.push("/" + app);
    },
  },
};
</script>
<style>
</style>
